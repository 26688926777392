@font-face {
  font-family: "booglaoo";
  src: url("../fonts/Boogaloo-Regular.ttf");
}

* {
  border-radius: 0 !important;
}

html {
  background: white;
}

body {
  font-size: 1.2em;

  section {
    background: rgb(250, 250, 250);
    border-bottom: 2px solid #dee2e5;
  }

  #main-container {
    margin-bottom: 1rem;
    box-shadow: 0 0 10px rgba(black, 0.2);
  }
}

iframe {
  max-width: 100%;
}
