@import "./node_modules/bulmaswatch/flatly/_variables.scss";

// Update Bulma's derived variables
@import "./node_modules/bulma/sass/utilities/initial-variables.sass";
$navbar-breakpoint: $widescreen;

@import "./node_modules/bulma/bulma.sass";
@import "./node_modules/bulmaswatch/flatly/_overrides.scss";

@import 'application';
@import 'home';
@import 'navbar';
@import 'page';
