.navbar {
  max-width: 1344px;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(black, 0.2);

  .navbar-end {
    margin-right: 2rem;
    .navbar-item {
      margin-right: 1rem;
      font-size: 1.3rem;
    }
  }

  #logo {
    @media only screen and (min-width: 1280px) {
      max-width: 680px;
    }
    max-width: 500px;
    margin-left: 1rem;
    width: 180px;
    img {
      max-height: none;
    }
  }

  .navbar-menu {
    margin-right: 0 !important;
    background: $primary !important;
    .navbar-item {
      color: white !important;
      &:hover { color: $link !important; }
      text-align: center;
    }
  }

  .navbar-burger {
    height: auto;
    width: 8rem;
  }
}
