.page {
  .hero {
    background-size: cover;
    background-repeat: no-repeat;
    .hero-body {
      background: linear-gradient(to right, rgba(black, 1), rgba(black, 0.8), rgba(black, 0.5), rgba(white, 0));
      * { color: white; }
    }
  }
  .content {
    img, image {
      max-height: 500px;
    }
  }
  .content.floating {
    img {
      height: 60px;
      float: left;
      margin-right: 40px;
      margin-bottom: 30px;
    }
    h1, h2, h3, h4, br {
      clear: both;
    }
  }
}
