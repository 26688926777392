.home {
  .hero {
    padding: 2% 30%;
    background: #34495e;
  }
  .card {
    height: 100%;

    &:hover {
      opacity: 0.8;
    }
  }
}
